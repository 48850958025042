@font-face {
  font-family: 'kingthings_willowregular';
  src: url('./assets/fonts/willow/kingthings_willow-webfont.woff2') format('woff2'),
       url('./assets/fonts/willow/kingthings_willow-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'kaushan_scriptregular';
  src: url('./assets/fonts/kaushanscript/kaushanscript-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/kaushanscript/kaushanscript-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'kidstarregular';
  src: url('./assets/fonts/kidstar/kidstar-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/kidstar/kidstar-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.footerPadding{
margin-bottom: 100px;

}

/* c8b568 */
/* 
#C7AE6A
.navbar-toggler-icon{
  background-color: #C7AE6A;
  border-color: #C7AE6A;
  } */
 
    .navbar .navbar-toggler{
      background-color: #c8b568;
  
      }


  
nav a.active{
  background-image: url(/src/assets/images/Tinycrown.png);
  background-repeat: no-repeat;
  background-position:center;
  color: #d5c28f !important; 
  text-shadow: 0 0 3px #000000, 0 0 7px #000000; 
 
}

nav a.active:hover {
  cursor: pointer;
  background-image: url(/src/assets/images/Tinycrown.png);
  background-repeat: no-repeat;
  background-position: center;
  color: #d5c28f !important;
}

nav a:hover {
  cursor: pointer;
  color: #c8b568 !important;
  background-position: center;
  background-repeat: no-repeat;
}

nav a.nav-link {
    color: #c8b568;
 
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.navTitleBorder {
 border-top: 2px solid #c8b568;
 border-bottom: 2px solid #c8b568;
 padding: 2px;

  }

 

  .navBarBrand {
    font-family: 'kidstarregular', Arial, sans-serif;
    /* font-family: 'kaushan_scriptregular', Arial, sans-serif; */
      color: #d5c28f;
      font-size: 2rem;
      text-shadow: 0 0 5px #c8b568, 0 0 7px #000000;  
      margin-right: 10px;
  }

 
    .navLink{
      font-family: 'kidstarregular', Arial, sans-serif;
      font-size:x-large;
      /* color: #78c0de; */
    }

    .rounded {
      border-radius: 10px;
     size: 20px ;
    }


    .queenFont{
      font-family: 'kidstarregular', Arial, sans-serif;
    }


    .footer{
     
      position:fixed;
      left:0;
      bottom:0;
      right:0;
      background-color: #262b3d; 
      color: #c8b568;
    }

  footer a:link {
    color:#c8b568;
    background-color: transparent;
    text-decoration: none;
  }

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.splashContainer {

  background-image: url(/src/assets/images/bkround_crown2.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
  height: 70vh; 
  background-position-y: bottom;
  position: relative;
}

.crownBkRound {
  background-image: url(/src/assets/images/bkround_crown.png);
  font-weight: bold;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;   
    /* background-color: #78c0de */
  /* background-size: auto; */
  
}


.splashFont{
  font-family: 'kidstarregular', Arial, sans-serif;
  color: #262b3d;  
  font-size: xx-large;
   text-shadow: 0 0 3px #262b3d, 0 0 7px #c8b568;  
  text-decoration: none; 
}


a.splashFont:hover {
  cursor: pointer;
  color: #4e577b;
}

.splashTextLoc{
 position: absolute;
 bottom: 0;
}

/* c8b568 */
.splashTitle{
  font-family: 'kidstarregular', Arial, sans-serif;
      /* 40px/16=2.5em */
  color: #b99a45; 
       /* 3A415C  */
  font-size: 3rem;
  text-shadow: 0 0 5px #d5c28f, 0 0 7px #262b3d;  
  text-shadow: 0 0 5px #000, 0 0 7px #000;  
  border-top: 3px solid #4e577b;
  border-bottom: 3px solid #4e577b;
  padding: 2px;
}


.aboutText{
  font-size:large;
  color: #d5c28f;
  background: rgba(58,65,92, 0.7);
}


.termsText{
    font-size:medium;
    color: #d5c28f;
    background: rgba(58,65,92);
  }


.btn-custom {
  background-color: #b99a45 !important;
  color: #262b3d !important;
  font-family: 'kidstarregular', Arial, sans-serif !important;
  font-size: large;
  font-weight: bold !important;
  border-color: #282c34!important;
}

.btn-custom:hover{
 
  background-color: #c8b568 !important;
  color: #262b3d !important;
  font-family: 'kidstarregular', Arial, sans-serif !important;
  font-weight: bolder !important;
  border-color: #282c34!important;
  font-size:large;
  font-weight: bold;
}



@media (max-width: 961px) {    
 
  nav a.active{
   background-position: left;
  }
  
  nav a.active:hover {
    background-position: left;
  }

  .splashTitle{
    font-size: 3rem;
  } 
}


@media (max-width: 576px ) {    
 
  .navBarBrand {
    font-size: 1rem;
    }

    .splashFont{
      font-size: x-large;
    }

}

/* @media (max-width: 540px ) {    
 
  .splashTextLoc{
    padding-bottom:  50px;
  
}
}

@media (max-width: 390px)   {    
  
  .splashTextLoc{
    padding-bottom: 30px;
  
}


} */


/* @media (max-width: 375px)   {    
  
  .splashTextLoc{
    padding-bottom:  70px;
  
}

} */

/* @media (min-width: 375px) and (min-height: 667){ 
  .splashTextLoc{
    padding-bottom:  70px;
  
}
}


  
@media (max-width: 360px)   {    
  
  .splashTextLoc{
    padding-bottom:  50px;
  
}
} */












 






.borderTest{
  border: #4a1a08 solid;
  
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}








