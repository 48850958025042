@font-face {
  font-family: 'kaushan_scriptregular';
  src: url('../assets/fonts/kaushanscript/kaushanscript-regular-webfont.woff2') format('woff2'),
       url('../assets/fonts/kaushanscript/kaushanscript-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}@font-face {
  font-family: 'kidstarregular';
  src: url('../assets/fonts/kidstar/kidstar-regular-webfont.woff2') format('woff2'),
       url('../assets/fonts/kidstar/kidstar-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}



  
  .car-card {
    width: 350px;
    height: 550px;
    border-radius: 10px;
    cursor: pointer;
    overflow: hidden; 
    transition: transform 0.5s;
 
  }

  .card-border{
    box-shadow: 3px 3px 8px 0px #262b3d;
  }

  .card-front{
 
    font-family: 'kidstarregular', Arial, sans-serif;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;   
    color: #c8b568; 
    width: 350px;
    height: 550px;
    background-image:  linear-gradient(to bottom, rgba(58, 65, 92, 1) 0%, rgba(58, 65, 92, 1) 100%), linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%);
    background-clip: content-box, padding-box; 
    padding: 10px;
  
  }

  .card-front:hover{
    border: solid #262b3d 3px;
    font-size: large;
    font-weight: bold;
    text-shadow: 0 0 5px #000, 0 0 7px #000000;  
   
    
  }

 


.cardHeader{
  background-color: rgba(58,65,92, 0.7);
  

}

  .cardTitle{
    font-family: 'kaushan_scriptregular', Arial, sans-serif;
    text-shadow: 0 0 3px #d5c28f, 0 0 7px #000;  
    
  } 


  .card-text { 

    height: 330px;
    /* overflow-y: auto;  */

    overflow-y: auto; /* Enables horizontal scrolling */
    overflow-x: hidden; /* Disables vertical scrolling */

    background-image: url(../assets/images/waterMark.png);
    background-position:center;
    background-repeat: no-repeat;
    border-radius: 10px;
    text-shadow: black solid;
   
  }

  

.cardDirections{
  font-family: 'kidstarregular', Arial, sans-serif;
  color: #b99a45;
  font-weight: bolder;
  text-shadow: 0 0 3px #000, 0 0 7px #000;  
}


.car-card img {
    max-width: 100%;
    max-height: 100%;
} 

.borderTest{
  border: white, solid;
}


.textBackround{
  background: rgba(0, 151, 19, 0.3);
}


.aboutImage {
  border-radius: 10px;
  margin-top: 1rem;
  background-color: #f5f5f5;
  border: 1px solid #000;
  /* Box Shadow */
  box-shadow: 1px 1px 10px 0px #d5c28f;
}

.eventCard{
  height: 100%;
}

.eventCard:hover{
  border: solid #262b3d 3px;
  font-size: large;
  font-weight: bold;
  text-shadow: 0 0 5px #000, 0 0 7px #000000;  
 
  
}

.eventInfo{
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

